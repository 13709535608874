import React, { useCallback, useContext, useMemo, useState } from "react";

import {
  ExpandedRowContext,
  MetricsFilter,
  MetricsSort,
  MetricsTable,
  MetricTableBody,
  MetricsTableConfig
} from "ExtensionV2/components/metricsTable/MetricsTable";
import { Dropdown, Segment, Table } from "semantic-ui-react";
import {
  COL_ATC,
  COL_ATC_RATE,
  COL_CONVERSIONS,
  COL_CONVERSION_RATE,
  COL_CONVERSION_VALUE,
  COL_COST_PER_MILLE,
  COL_DETAIL_PAGE_VIEWS,
  COL_COST_PER_CLICK,
  COL_CLICK_RATE,
  COL_CLICKS,
  COL_IMPRESSIONS,
  COL_MARKETPLACE_CLICKS,
  COL_NTB_CONVERSION_RATE,
  COL_NTB_CONVERSIONS,
  COL_NTB_REVENUE,
  COL_NTB_REVENUE_RATE,
  COL_NTB_ROAS,
  COL_NTB_UNITS_SOLD,
  COL_NTB_UNITS_SOLD_RATE,
  COL_ROAS,
  COL_SPEND,
  COL_UNITS_SOLD,
  COL_ACCOUNT_ID,
  COL_AD_ID,
  COL_AD_SET_ID,
  COL_CAMPAIGN_ID,
  COL_NAME,
  COL_BRAND_REFERRAL_BONUS,
  COL_COST_PER_CONVERSION,
  COL_COST_PER_NTB_CONVERSION,
  COL_COST_PER_ADD_TO_CART,
  COL_METRICS_START,
  COL_METRICS_END
} from "../../components/metricsTable/commonColumns";
import {
  emptyRow,
  FacebookTableData,
  useFacebookTableData
} from "./useFacebookTableData";
import { useURLParamOrLocalStorageObject } from "ExtensionV2/state/useURLParamOrLocalStorageObject";
import { None, removeNullAndUndefined } from "Common/utils/tsUtils";
import { FilterArguments } from "ExtensionV2/components/metricsTable/filters";
import {
  CompareMetricsLoadingContext,
  PrimaryMetricsLoadingContext
} from "ExtensionV2/components/metricsTable/contexts";
import {
  MetricsTableBodyRow,
  StyledMetricsTableFooterRow
} from "ExtensionV2/components/metricsTable/rows/MetricsTableBodyRow";
import { Column } from "ExtensionV2/components/metricsTable/column";
import { useSessionSite } from "ExtensionV2/queries/useSessionSite";
import {
  DatePickerLabel,
  GlobalDatePicker
} from "ExtensionV2/components/GlobalDatePicker";
import {
  GlobalDateContext,
  WantsExcludeAmazonLagPeriodContext
} from "ExtensionV2";
import { extractErrorMessage } from "Common/errors/error";
import { toast } from "react-toastify";
import {
  FB_COL_AD_NAME,
  FB_COL_AD_SET_NAME,
  FB_COL_ATTRIBUTION_START_DATE,
  FB_COL_AD_TARGET,
  FB_COL_BID_AMOUNT,
  FB_COL_CAMPAIGN_NAME,
  FB_COL_DAILY_BUDGET,
  FB_COL_EFFECTIVE_STATUS,
  FB_COL_LIFETIME_BUDGET,
  FB_COL_NAME,
  FB_COL_STATUS
} from "./columns";
import { rowDataToResourceType } from "./cells";
import styled from "styled-components";
import { useWantsProOperatorFeatures } from "Common/utils/featureFlags";
import { useNavigate } from "react-router-dom";
import { fetchDailyFacebookTableData } from "./fetchDailyFacebookTableData";
import { DashboardTable } from "Common/proto/edge/grpcwebPb/grpcweb_DashboardTable_pb";
import {
  CSVExportOptions,
  CSVGeneratorFn
} from "ExtensionV2/components/metricsTable/CSVExportManager";
import { arrayToCSV } from "Common/utils/csv";
import { stringForEnum } from "Common/utils/proto";

const FacebookPageLayout = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr min-content 0.5fr 8fr;
  gap: 0px 0px;
  grid-template-areas:
    "dates"
    "filter-pills"
    "table-controls"
    "table";

  > div.facebook-page-dates-area {
    grid-area: dates;
    margin-bottom: 0.75rem;
  }

  > div.metrics-table-controls-area {
    grid-area: table-controls;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .metrics-table-controls {
      display: flex;
      flex-direction: row;
    }

    .metrics-table-primary-action-button {
      height: 2.5rem;
    }
  }

  > div.metrics-table-filters-area {
    grid-area: filter-pills;
    margin-bottom: 1rem;
  }

  > div.metrics-table-table-area {
    grid-area: table;
    overflow: auto;
  }
`;

// col -> isDefault
const defaultFacebookTableColumns = new Map<Column<FacebookTableData>, boolean>(
  [
    // Attributes
    [FB_COL_NAME, true],
    [FB_COL_STATUS, true],
    [FB_COL_EFFECTIVE_STATUS, true],
    [FB_COL_AD_TARGET, false],
    [FB_COL_ATTRIBUTION_START_DATE, false],
    [COL_ACCOUNT_ID, false],
    [COL_AD_ID, false],
    [COL_AD_SET_ID, false],
    [COL_CAMPAIGN_ID, false],
    [FB_COL_AD_NAME, false],
    [FB_COL_AD_SET_NAME, false],
    [FB_COL_CAMPAIGN_NAME, false],
    // Budgets
    [FB_COL_DAILY_BUDGET, true],
    [FB_COL_LIFETIME_BUDGET, false],
    [FB_COL_BID_AMOUNT, false],
    // Ad Platform Side
    [COL_IMPRESSIONS, true],
    [COL_COST_PER_MILLE, false],
    [COL_CLICKS, true],
    [COL_CLICK_RATE, false],
    [COL_SPEND, true],
    [COL_COST_PER_CLICK, false],
    // Marketplace Side
    [COL_DETAIL_PAGE_VIEWS, false],
    [COL_ATC, true],
    [COL_ATC_RATE, false],
    [COL_COST_PER_ADD_TO_CART, false],
    [COL_CONVERSIONS, true],
    [COL_COST_PER_CONVERSION, false],
    [COL_CONVERSION_RATE, false],
    [COL_NTB_CONVERSIONS, false],
    [COL_COST_PER_NTB_CONVERSION, false],
    [COL_NTB_CONVERSION_RATE, false],
    [COL_UNITS_SOLD, false],
    [COL_NTB_UNITS_SOLD, false],
    [COL_NTB_UNITS_SOLD_RATE, false],
    [COL_CONVERSION_VALUE, true],
    [COL_NTB_REVENUE, false],
    [COL_NTB_REVENUE_RATE, false],
    [COL_BRAND_REFERRAL_BONUS, true],
    [COL_ROAS, true],
    [COL_NTB_ROAS, false]
  ]
);

// A table options object should be JSON serializable
export type FacebookTableOptions = {
  level?: string;
  expandedRows?: Array<string>;
  filters?: Array<{ column: string; args: FilterArguments }>;
  sort?: {
    column: string;
    direction: string;
  };
  columns?: Array<string>; // column name
  showFractions?: boolean;
  amazonLagPeriod?: boolean;
  respectAdAttributionDate?: boolean;
};

export enum FbTableViewLevel {
  CAMPAIGN = "campaign",
  AD_SET = "ad set",
  AD = "ad",
  UNKNOWN = ""
}

// Default table configuration
const defaultOptions: FacebookTableOptions = {
  level: FbTableViewLevel.CAMPAIGN,
  respectAdAttributionDate: true
};

// Local storage key for the table options
const tableOptionsKey = "facebookTableConfig";

// URL parameter name for the table options
const tableOptionsParam = "fb";

export const ViewLevelContext = React.createContext<FbTableViewLevel>(
  FbTableViewLevel.UNKNOWN
);

export function FacebookPageV2(): JSX.Element {
  const { siteAlias, siteName } = useSessionSite();
  const { startDate, endDate, compareTo } = useContext(GlobalDateContext);
  const navigate = useNavigate();
  const wantsProFeatures = useWantsProOperatorFeatures();

  const [tableOptions, setTableOptions] = useURLParamOrLocalStorageObject({
    localStorageKey: tableOptionsKey,
    urlParam: tableOptionsParam,
    defaultValue: defaultOptions
  });

  const [excludeLagPeriod, setExcludeLagPeriod] = useContext(
    WantsExcludeAmazonLagPeriodContext
  );
  if (
    tableOptions.amazonLagPeriod &&
    tableOptions.amazonLagPeriod !== excludeLagPeriod
  ) {
    setExcludeLagPeriod(tableOptions.amazonLagPeriod);
  }

  const [viewLevel, setViewLevel] = useState<FbTableViewLevel>(() => {
    if (
      tableOptions.level === FbTableViewLevel.CAMPAIGN ||
      tableOptions.level === FbTableViewLevel.AD_SET ||
      tableOptions.level === FbTableViewLevel.AD
    ) {
      return tableOptions.level;
    }
    return FbTableViewLevel.CAMPAIGN;
  });

  const {
    data: tableRows,
    primaryMetricsLoading,
    prevMetricsFetching,
    error: primaryMetricsError
  } = useFacebookTableData(
    siteAlias,
    startDate,
    endDate,
    tableOptions.respectAdAttributionDate ?? true,
    compareTo
  );

  const csvGenerator = useFacebookCSVGenerator(
    siteName,
    siteAlias,
    startDate,
    endDate,
    viewLevel,
    tableRows,
    tableOptions.respectAdAttributionDate ?? true,
    [...defaultFacebookTableColumns.keys()]
  );

  if (primaryMetricsError) {
    console.error(primaryMetricsError);
    const errorMessage = extractErrorMessage(primaryMetricsError);
    toast.error(
      <div>
        <p>
          There was an error when retrieving your data from Meta. You may see
          incomplete or missing data in the table.
        </p>

        {errorMessage && (
          <div>
            <p>Additional Info: {extractErrorMessage(errorMessage)}</p>
          </div>
        )}
      </div>,
      {
        toastId: errorMessage
      }
    );
  }

  const handleUpdateViewLevel = (level: FbTableViewLevel) => {
    setViewLevel(level);
    setTableOptions({ ...tableOptions, level });
  };

  const handleUpdateExpandedRows = (rows: Set<string>) => {
    setTableOptions({ ...tableOptions, expandedRows: [...rows] });
  };

  const handleUpdateSort = (sort: MetricsSort<FacebookTableData>): void => {
    setTableOptions({
      ...tableOptions,
      sort: {
        column: String(sort.column.dataName),
        direction: sort.direction
      }
    });
  };

  const handleSetShowFractions = (show: boolean) => {
    setTableOptions({ ...tableOptions, showFractions: show });
  };

  const handleSetRespectAdAttributionDate = (respect: boolean) => {
    setTableOptions({ ...tableOptions, respectAdAttributionDate: respect });
  };

  const handleAmazonLagPeriod = (exclude: boolean) => {
    setExcludeLagPeriod(exclude);
    setTableOptions({ ...tableOptions, amazonLagPeriod: exclude });
  };

  const handleUpdateFilters = (
    filters: Array<MetricsFilter<FacebookTableData>>
  ) => {
    setTableOptions({
      ...tableOptions,
      filters: filters.map(f => ({
        column: f.column.dataName,
        args: f.args
      }))
    });
  };

  const handleUpdateColumns = (
    columns: Map<Column<FacebookTableData>, boolean>
  ) => {
    const activeColumnNames = [...columns.entries()]
      .filter(([_, isActive]) => isActive)
      .map(([col]) => String(col.dataName));

    setTableOptions({
      ...tableOptions,
      columns: activeColumnNames
    });
  };

  const tableColumns = useMemo(() => {
    if (!wantsProFeatures) {
      return defaultFacebookTableColumns;
    }

    const columns = new Map(defaultFacebookTableColumns);
    columns.set(COL_MARKETPLACE_CLICKS, false);
    return columns;
  }, [wantsProFeatures]);

  const tableConfig = useMemo(() => {
    const config: MetricsTableConfig<FacebookTableData> = {
      defaultColumns: tableColumns,
      rows: tableRows || [],
      BodyElement: FacebookTableBody,
      FooterElement: FacebookTableFooter,
      tableOptions: {
        storageKey: tableOptionsKey,
        urlParam: tableOptionsParam,
        defaultValue: defaultOptions
      }
    };
    return config;
  }, [tableColumns, tableRows]);

  const columnList = Array.from(tableColumns.keys());

  const initialSort: MetricsSort<FacebookTableData> = {
    column: COL_NAME,
    direction: "asc"
  };
  const sortColumn = findColumnByString(
    tableOptions?.sort?.column,
    Array.from(tableConfig.defaultColumns.keys())
  );
  if (sortColumn) {
    initialSort.column = sortColumn;
    initialSort.direction =
      tableOptions.sort?.direction === "asc" ? "asc" : "desc";
  }

  const initialFilters = getFiltersFromTableOptions(
    tableOptions.filters,
    Array.from(tableConfig.defaultColumns.keys())
  );

  let initialColumns: Map<Column<FacebookTableData>, boolean>;
  if (tableOptions.columns) {
    initialColumns = new Map();
    for (const columnName of tableOptions.columns) {
      const col = findColumnByString(columnName, Array.from(columnList));
      if (col) {
        initialColumns.set(col, true);
      }
    }
    for (const col of columnList) {
      if (!initialColumns.has(col)) {
        initialColumns.set(col, false);
      }
    }
  } else {
    initialColumns = defaultFacebookTableColumns;
  }

  return (
    <Segment
      style={{
        display: "flex",
        height: "100%"
      }}
    >
      <FacebookPageLayout>
        <div className="facebook-page-dates-area">
          <GlobalDatePicker offerCompare={true}>
            <LevelPicker
              viewLevel={viewLevel}
              handleUpdateViewLevel={handleUpdateViewLevel}
            />
          </GlobalDatePicker>
        </div>
        <PrimaryMetricsLoadingContext.Provider value={primaryMetricsLoading}>
          <CompareMetricsLoadingContext.Provider value={prevMetricsFetching}>
            <ViewLevelContext.Provider value={viewLevel}>
              <MetricsTable<FacebookTableData>
                tableConfig={tableConfig}
                initialFilters={initialFilters}
                initialSort={initialSort}
                initialExpandedRows={new Set(tableOptions.expandedRows || [])}
                initialColumns={initialColumns}
                initialShowFractions={tableOptions.showFractions}
                initialRespectAdAttributionDate={
                  tableOptions.respectAdAttributionDate ?? true
                }
                excludeAmazonLagPeriod={excludeLagPeriod}
                primaryButtonText="Add Attribution"
                onSetExpandedRows={handleUpdateExpandedRows}
                onUpdateSort={handleUpdateSort}
                onUpdateFilters={handleUpdateFilters}
                onUpdateColumns={handleUpdateColumns}
                onSetShowFractions={handleSetShowFractions}
                onSetRespectAdAttributionDate={
                  handleSetRespectAdAttributionDate
                }
                onSetExcludeAmazonLagPeriod={handleAmazonLagPeriod}
                onPrimaryActionClick={() => {
                  navigate("../dashboard/meta/add-attribution");
                }}
                csvGenerator={csvGenerator}
              />
            </ViewLevelContext.Provider>
          </CompareMetricsLoadingContext.Provider>
        </PrimaryMetricsLoadingContext.Provider>
      </FacebookPageLayout>
    </Segment>
  );
}

function findColumnByString(
  columnName: string | None,
  columns: Array<Column<FacebookTableData>>
): Column<FacebookTableData> | undefined {
  if (!columnName) {
    return;
  }
  return columns.find(c => c.dataName === columnName);
}

function getFiltersFromTableOptions(
  filters: Array<{ column: string; args: FilterArguments }> | None,
  columns: Array<Column<FacebookTableData>>
): Array<MetricsFilter<FacebookTableData>> {
  if (!filters || !filters.length) {
    return [];
  }

  return filters
    .map(filter => {
      const { column: columnName, args } = filter;
      const column = findColumnByString(columnName, columns);
      if (!column) {
        return;
      }
      return {
        column,
        args
      };
    })
    .filter(removeNullAndUndefined);
}

function isFbTableViewLevel(level: unknown): level is FbTableViewLevel {
  return (
    level === FbTableViewLevel.CAMPAIGN ||
    level === FbTableViewLevel.AD_SET ||
    level === FbTableViewLevel.AD
  );
}

type NestedResource = Map<
  string,
  {
    row: FacebookTableData;
    children: NestedResource;
  }
>;

const FacebookTableBody: MetricTableBody<FacebookTableData> = ({
  rows,
  columns
}: {
  rows: Array<FacebookTableData>;
  columns: Array<Column<FacebookTableData>>;
}) => {
  const viewLevel = useContext(ViewLevelContext);
  const [expandedRows] = useContext(ExpandedRowContext);

  const campaigns: NestedResource = new Map();
  const adSets: NestedResource = new Map();
  const ads: NestedResource = new Map();

  rows.forEach(row => {
    const [resourceType] = rowDataToResourceType(row);
    if (resourceType === DashboardTable.ObjectType.Option.CAMPAIGN) {
      campaigns.set(row.campaignID, {
        row,
        children: new Map()
      });
    }
  });

  rows.forEach(row => {
    const [resourceType] = rowDataToResourceType(row);
    if (resourceType === DashboardTable.ObjectType.Option.FACEBOOK_AD_SET) {
      adSets.set(row.adSetID, {
        row,
        children: new Map()
      });

      const parentCampaign = campaigns.get(row.campaignID);
      if (parentCampaign) {
        parentCampaign.children.set(row.adSetID, {
          row,
          children: new Map()
        });
      }
    }
  });

  rows.forEach(row => {
    const [resourceType] = rowDataToResourceType(row);
    if (resourceType === DashboardTable.ObjectType.Option.FACEBOOK_AD) {
      ads.set(row.adID, {
        row,
        children: new Map()
      });

      const parentAdSet = adSets.get(row.adSetID);
      if (parentAdSet) {
        parentAdSet.children.set(row.adID, {
          row,
          children: new Map()
        });
      }

      const parentCampaign = campaigns.get(row.campaignID);
      if (parentCampaign) {
        const parentAdSet = parentCampaign.children.get(row.adSetID);
        if (parentAdSet) {
          parentAdSet.children.set(row.adID, {
            row,
            children: new Map()
          });
        }
      }
    }
  });

  let topLevel = campaigns;
  if (viewLevel === FbTableViewLevel.AD_SET) {
    topLevel = adSets;
  } else if (viewLevel === FbTableViewLevel.AD) {
    topLevel = ads;
  }

  return (
    <>
      {[...topLevel.values()].map(({ row, children }) => {
        const childResources = [...children.values()];
        return (
          <Table.Body key={row.rowID}>
            <MetricsTableBodyRow
              depth={0}
              key={row.rowID}
              data={row}
              columns={columns}
              childRows={childResources.map(({ row }) => row)}
            />

            {expandedRows.has(row.rowID) &&
              childResources.map(({ row: secondLevelRow, children }) => {
                const secondLevelChildren = [...children.values()];
                return (
                  <React.Fragment key={secondLevelRow.rowID}>
                    <MetricsTableBodyRow
                      depth={1}
                      key={secondLevelRow.rowID}
                      data={secondLevelRow}
                      columns={columns}
                      childRows={secondLevelChildren.map(({ row }) => row)}
                    />

                    {expandedRows.has(secondLevelRow.rowID) &&
                      secondLevelChildren.map(({ row: thirdLevelRow }) => (
                        <MetricsTableBodyRow
                          depth={2}
                          key={thirdLevelRow.rowID}
                          data={thirdLevelRow}
                          columns={columns}
                          childRows={[]}
                        />
                      ))}
                  </React.Fragment>
                );
              })}
          </Table.Body>
        );
      })}
    </>
  );
};

const FacebookTableFooter: MetricTableBody<FacebookTableData> = ({
  rows,
  columns
}: {
  rows: Array<FacebookTableData>;
  columns: Array<Column<FacebookTableData>>;
}) => {
  const viewLevel = useContext(ViewLevelContext);

  let title = "";
  if (viewLevel === FbTableViewLevel.CAMPAIGN) {
    rows = rows.filter(row => !row.adSetID && !row.adID);
    title = "Campaigns";
  } else if (viewLevel === FbTableViewLevel.AD_SET) {
    rows = rows.filter(row => row.adSetID && !row.adID);
    title = "Ad Sets";
  } else {
    rows = rows.filter(row => row.adID);
    title = "Ads";
  }

  const prevRows = rows
    .map(row => {
      if (!row.prev) {
        return;
      }
      return { rowID: row.rowID, ...row.prev };
    })
    .filter(removeNullAndUndefined);

  return (
    <Table.Footer style={{ position: "sticky", bottom: 0, zIndex: 4 }}>
      <StyledMetricsTableFooterRow>
        <td>
          <input type="checkbox" />
        </td>
        {columns.map(column => {
          if (column.dataName === "name") {
            return (
              <Table.Cell className="name" key={String(column.dataName)}>
                <div>
                  Total ({rows.length} {title})
                </div>
              </Table.Cell>
            );
          } else {
            return (
              <column.RenderCell
                key={column.dataName}
                rowData={emptyRow()}
                rowID="totals"
                val={column.totalFn ? column.totalFn(rows) : ""}
                prev={
                  column.totalFn && prevRows.length
                    ? column.totalFn(prevRows)
                    : ""
                }
              />
            );
          }
        })}
      </StyledMetricsTableFooterRow>
    </Table.Footer>
  );
};

const LevelPicker = ({
  viewLevel,
  handleUpdateViewLevel
}: {
  viewLevel: FbTableViewLevel;
  handleUpdateViewLevel: (level: FbTableViewLevel) => void;
}) => {
  return (
    <div>
      <DatePickerLabel>Level</DatePickerLabel>
      <Dropdown
        style={{ marginBottom: "1rem" }}
        selection
        options={[
          {
            key: FbTableViewLevel.CAMPAIGN,
            text: "Campaign",
            value: FbTableViewLevel.CAMPAIGN
          },
          {
            key: FbTableViewLevel.AD_SET,
            text: "Ad Set",
            value: FbTableViewLevel.AD_SET
          },
          {
            key: FbTableViewLevel.AD,
            text: "Ad",
            value: FbTableViewLevel.AD
          }
        ]}
        onChange={(e, { value }) => {
          if (!isFbTableViewLevel(value)) {
            return;
          }
          handleUpdateViewLevel(value);
        }}
        value={viewLevel}
      />
    </div>
  );
};

function mapFacebookTableRowsToCSVRows(
  tableRows: Array<FacebookTableData>,
  columns: Array<Column<FacebookTableData>>
): Array<Array<string>> {
  const formattedRows: Array<Array<string>> = tableRows.map(row => {
    const formattedVals: Array<string> = [];
    for (const column of columns) {
      formattedVals.push(column.formatCSV(row));
    }
    return formattedVals;
  });

  return formattedRows;
}

function generateExportName(
  siteName: string,
  objectType: DashboardTable.ObjectType.Option,
  startDate: string,
  endDate: string,
  dailyData: boolean
): string {
  const objectTypeString = stringForEnum(
    DashboardTable.ObjectType.Option,
    objectType
  ).toLowerCase();

  const type = dailyData ? "daily" : "aggregated";

  return `${siteName}_${objectTypeString}_${type}_${startDate}_${endDate}.csv`;
}

const useFacebookCSVGenerator = (
  siteName: string,
  siteAlias: string,
  startDate: string,
  endDate: string,
  viewLevel: FbTableViewLevel,
  tableRows: Array<FacebookTableData> | undefined,
  respectAdAttributionDate: boolean,
  allColumns: Array<Column<FacebookTableData>>
): CSVGeneratorFn<FacebookTableData> =>
  useCallback(
    async (
      columns: Array<Column<FacebookTableData>>,
      exportOptions: CSVExportOptions
    ): Promise<[string, string]> => {
      let objectType = DashboardTable.ObjectType.Option.CAMPAIGN;
      switch (viewLevel) {
        case "campaign":
          objectType = DashboardTable.ObjectType.Option.CAMPAIGN;
          break;
        case "ad set":
          objectType = DashboardTable.ObjectType.Option.FACEBOOK_AD_SET;
          break;
        case "ad":
          objectType = DashboardTable.ObjectType.Option.FACEBOOK_AD;
          break;
      }

      let rows: Array<FacebookTableData> = [];
      if (exportOptions.dailyData) {
        rows = await fetchDailyFacebookTableData({
          siteAlias,
          startDate,
          endDate,
          objectType,
          respectAdAttributionDate
        });

        if (!rows.length) {
          return ["", ""];
        }

        rows.sort((a, b) => {
          if (a.metricsStart === b.metricsStart) {
            return 0;
          }

          return (a?.metricsStart ?? 0) < (b?.metricsStart ?? 0) ? -1 : 1;
        });
      } else {
        rows = (tableRows || [])?.filter(row => {
          const [resourceType] = rowDataToResourceType(row);
          return resourceType === objectType;
        });
      }

      const csvColumns = [
        COL_METRICS_START,
        COL_METRICS_END,
        ...(exportOptions.allColumns ? allColumns : columns)
      ];
      const csvDataRows = mapFacebookTableRowsToCSVRows(rows, csvColumns);
      const headerRow = csvColumns.map(col => col.displayName);
      const exportName = generateExportName(
        siteName,
        objectType,
        startDate,
        endDate,
        exportOptions.dailyData
      );
      return [exportName, arrayToCSV([headerRow, ...csvDataRows])];
    },
    [allColumns, endDate, siteAlias, siteName, startDate, tableRows, viewLevel]
  );
